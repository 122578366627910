import { template as template_52ba1a4ac0c843179f4fbe61b414c3a5 } from "@ember/template-compiler";
const FKText = template_52ba1a4ac0c843179f4fbe61b414c3a5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
