import { template as template_7f80d12181f44f53a622eb7079ef88db } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_7f80d12181f44f53a622eb7079ef88db(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
